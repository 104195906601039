<template>
    <div>
        <ts-page-title
            :title="$t('transportationGlobalFee.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('transportationGlobalFee.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <a-button
                        type="primary"
                        @click="addNew"
                        v-if="$can('create-transportation-global-fees')"
                    >
                        {{ $t('addNew') }}
                    </a-button>
                    <div class="tw-space-x-3">
                        <a-tooltip
                            :title="$t('transportationGlobalFee.search')"
                        >
                            <a-input-search
                                v-model="search"
                                :placeholder="
                                    $t('transportationGlobalFee.search')
                                "
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
                :rowGroup="{
                    field: 'service_type_name_en'
                }"
            >
                <template v-slot="{ record }">
                    <td>
                        {{ record.service_type_name_en }}
                    </td>
                    <td class="tw-text-blue-400 tw-font-semibold">
                        {{ record.num_package_from }}
                    </td>
                    <td class="tw-text-red-400 tw-font-semibold">
                        {{ record.num_package_to }}
                    </td>
                    <td>
                        {{ record.currency_code }}
                    </td>
                    <td class="tw-text-blue-700 tw-font-extrabold">
                        {{ record.currency_code + ' ' + record.fee_amount }}
                    </td>
                    <td>
                        {{ record.charge_type }}
                    </td>
                    <td class="text-center tw-space-x-3">
                        <a
                            v-if="
                                $can('update-transportation-global-fees') &&
                                !record.is_deleted
                            "
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                        <a-popconfirm
                            v-if="
                                $can('delete-transportation-global-fees') &&
                                !record.is_deleted
                            "
                            placement="leftTop"
                            :title="$t('are_you_sure_to_delete')"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                            @confirm="deleteRecord(record)"
                        >
                            <a href="#" :disabled="record._deleting">
                                <i
                                    class="fas fa-circle-notch spin"
                                    v-if="record._deleting"
                                ></i>
                                <i class="fa fa-trash text-red" v-else></i>
                            </a>
                        </a-popconfirm>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('transportationGlobalFee.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
            width="400px"
        >
            <FormAction @cancel="clearEdit" />
        </a-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import FormAction from './form'
export default {
    name: 'transportationGlobalFee',
    components: {
        FormAction
    },
    data() {
        return {
            loading: false,
            showForm: false
        }
    },
    computed: {
        ...mapState('finance/transportationGlobalFee', [
            'resources',
            'pagination'
        ]),
        search: {
            get() {
                return this.$store.state.finance.transportationGlobalFee.search
            },
            set(newValue) {
                this.$store.commit(
                    'finance/transportationGlobalFee/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'finance/transportationGlobalFee/RESET_CURRENT_PAGE'
                )
            }
        },
        columns() {
            return [
                { name: this.$t('transportationGlobalFee.serviceType') },
                { name: this.$t('transportationGlobalFee.numPackageFrom') },
                { name: this.$t('transportationGlobalFee.numPackageTo') },
                { name: this.$t('transportationGlobalFee.currency') },
                {
                    name: this.$t('transportationGlobalFee.amount'),
                    style: 'width:150px'
                },
                { name: this.$t('transportationGlobalFee.chargeType') },
                {
                    name: this.$t('actions'),
                    style: 'text-align:center; width: 1px;'
                }
            ]
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true
            this.$store
                .dispatch('finance/transportationGlobalFee/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.$notify({ type: 'error', text: error.message })
                })
        },
        addNew() {
            this.$router.push({ name: 'transportation-global-fee-create' })
        },
        edit(record) {
            this.$store.commit(
                'finance/transportationGlobalFee/SET_EDIT_DATA',
                record
            )
            this.showForm = true
        },
        clearEdit() {
            this.$store.commit(
                'finance/transportationGlobalFee/SET_EDIT_DATA',
                {}
            )
            this.showForm = false
            this.fetchData()
        },
        deleteRecord(record) {
            record._deleting = true
            this.$store
                .dispatch(
                    'finance/transportationGlobalFee/destroy',
                    record.global_fee_id
                )
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.fetchData()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    record._deleting = false
                })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('finance/exchangeRate/RESET_STATE')
        next()
    }
}
</script>
